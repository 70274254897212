#site-header {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
   padding: 14px 0;
   
  .poweredby {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 1rem;
  }

  .externalyticslogo {
    //filter: contrast(0.5);

    @include media-breakpoint-up(md) {
      max-width: 160px;
      margin-left: 0.6rem;
    }

    @include media-breakpoint-down(md) {
      max-width: 35%;
      margin-left: 0.6rem;
    }
  }

  .openrep-logo-header {
    //filter: contrast(0.5);

    @include media-breakpoint-up(md) {
      min-width: 200px;
      max-width: 250px;
      margin-left: 0.6rem;
    }

    @include media-breakpoint-down(md) {
      min-width: 150px;
      max-width: 150px;
      margin-left: 0.6rem;
    }
  }

  small {
    font-size: $font-small;
  }

  .applogo {
    max-width: 270px;
    height: auto;
    //@extend .d-none;
    @extend .d-md-block;

    @include media-breakpoint-down(lg) {
      padding-top: 10px;
    }
  }

  .icsico {
    max-width: 55px;
    height: auto;
    @extend .d-block;
    @extend .d-md-none;
  }

  .poweredbylogo {
    max-width: 86px;
    height: auto;
  }

   .userico {
      max-width: 30px;
      height: auto;
      fill: $primary-light;
   }

  .navbar-nav {
    @media (min-width: 600px) {
      flex-direction: row;
      align-items: center !important;
    }
  }

  .navbar {
    @extend .align-items-center;

    @include media-breakpoint-down(md) {
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }

    @media (min-width: 2236px) {
      //max-width: 97%;
      margin: 0 auto;
      padding-top: 2rem;
    }
  }

  .left-side,
  .right-side {
    @extend .align-items-center;

    @include media-breakpoint-down(md) {
      //-ms-flex-align: start !important;
      //align-items: flex-start !important;
    }
  }

  .left-side {
    .navbar-brand {
      margin-right: 20px;

      @include media-breakpoint-down(md) {
        margin-right: 0px;
        padding-left: 0px;
        margin-top: -1px;
      }
    }
  }

  .right-side {
    &.navbar-nav {
      @include media-breakpoint-down(md) {
        flex-direction: row !important;
      }
    }

    .nav-link,
      .form-inline,
      //.user.dropdown,
      .about.dropdown {
      margin-left: 12px;
    }

    .form-inline {
      .form-control {
        border: 0px;
      }

      &.select-currency {
        width: 90px;
      }

      &.select-lang {
        width: 85px;
      }

      &.select-lang,
      &.select-currency,
      &.select-about {
        @include media-breakpoint-down(md) {
          width: 100%;

          > div {
            width: 90%;
          }
        }

        .custom-select__dropdown-indicator {
          svg {
            fill: $primary-light;
          }
        }

        .custom-select__dropdown-indicator {
          padding-left: 0px;
        }

        .custom-select__control {
          @include media-breakpoint-down(md) {
            justify-content: space-between;
          }
        }
      }

      &.select-about {
        .custom-select__value-container {
          width: 100px;

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }

        .custom-select__dropdown-indicator {
          width: 68%;

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
      }
    }

    .dropdown-menu {
      .dropdown-item {
        a {
          color: $primary-light;
        }
      }
    }
  }

  .powered-by-header {
    text-align: inherit;

    @media (min-width: 600px) {
      text-align: left;
      margin-right: 2rem;
    }

    @media (max-width: 991px) {
      display: none;
    }

    svg {
      width: 100px;
      height: auto;
    }
  }

  .search-bar {
    width: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: width 1s;

    @include media-breakpoint-down(md) {
      padding-top: 5px;
      margin-left: 5px !important;
      margin-right: 5px !important;
    }

    .search-box {
      width: 80%;
    }

    .search-btn {
      width: 20px;
      padding-left: 0px;
      padding-right: 0px;
    }

    &:hover {
      width: 200px;
      transition: width 1s;

      @include media-breakpoint-down(lg) {
        width: 100px;
      }

      @include media-breakpoint-down(md) {
        width: 200px;
      }

      @include media-breakpoint-down(sm) {
        width: 100px;
      }
    }
  }

  .dropdown-table {
    #tablet-toggle {
      &.dropdown-toggle {
        padding-top: 0px;
        padding-left: 2px;
        padding-right: 5;
      }
    }

    .dropdown-menu {
      position: absolute;
      right: 0px;
      left: auto;

      .dropdown-item {
        padding-right: 0.7rem !important;
        padding-left: 0 !important;
        color: $primary-light;
        padding: inherit 0.7rem inherit 0 !important;
      }

      .form-inline {
        .form-control {
          padding-left: 0px;
        }
      }

      .form-group {
        width: 100%;

        .form-control {
          width: 100%;
        }
      }

      .dropdown.nav-item {
        //&.user,
        &.about {
          margin-left: 0px;
          margin-right: 0px;

          .nav-link {
            &.dropdown-toggle::after {
              right: 26px;
              position: absolute;
              top: 15px;
            }
          }

          svg {
            margin-left: 5px;
          }
        }

        &.about {
          .nav-link {
            margin-left: 20px;
          }
        }
      }
    }
  }

  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: $primary-light;
      }
    }
  }
}

.sub-navbar {
   @extend .d-lg-block;
   font-size: $font-20px;
   border-radius: 0 0 12px 12px;
   padding: 0  1.5rem;
   background-color: white;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

   @media (min-width: 2236px) {
     padding-left: 2.5rem;
     padding-right: 2.5rem;
   }

   .navbar-nav {
     .nav-item {
       a {
         padding-top: 1.7rem;
         padding-bottom: 1.7rem;
         font-weight: $font-w-medium;
         color: $darkgrey;

         &:hover, &:focus, &.nav-link.active {
           box-shadow: inset 0px -3px 0px 0px $third-color !important;
         }
       }

       .nav-link {
         padding-right: 0rem;
         padding-left: 0rem;
         margin-right: 0.5rem;
         margin-left: 0.5rem;
       }
     }

     .dropdown-toggle.btn {
         background-color: inherit;
         border: none;
         text-transform: none;
         font-weight: $font-w-medium;
         font-size: $font-20px;
         color: #5e7782;
         border-radius: 0;

         &:hover, &:focus, &.nav-link.active, &:active {
            background-color: inherit !important;
            box-shadow: inset 0px -3px 0px 0px $third-color !important;
         }
     }
   }

   .dropdown-menu {
      text-align: center !important;

      .dropdown-item {
         padding-top: 0.7rem !important;
         padding-bottom: 0.7rem !important;
      }
      
      a:hover {
         background-color: $third-color !important;
         box-shadow: none !important;
      }
   }
 }

 .sub-nav-dropdown.horizontal {
   .btn::after {
      border-top: 0em;
      border-right: 0em;
      border-bottom: 0;
      border-left: 0.3em;
      border: solid $darkgrey;
      border-width: 0 2px 2px 0;
      padding: 3px;
      display: inline-block;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-left: 10px;
      vertical-align: 3px;
   }
 }


.dropdown-toggle {
  &.btn-primary {
   &::after {
      border-top: 0em;
      border-right: 0em;
      border-bottom: 0;
      border-left: 0.3em;
      border: solid white;
      border-width: 0 2px 2px 0;
      padding: 3px;
      display: inline-block;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-left: 5px;
      margin-right: 3px;
      vertical-align: 2px;
   }
  }

  &::after {
   border-top: 0em;
   border-right: 0em;
   border-bottom: 0;
   border-left: 0.3em;
   border: solid $darkgrey;
   border-width: 0 2px 2px 0;
   padding: 3px;
   display: inline-block;
   transform: rotate(45deg);
   -webkit-transform: rotate(45deg);
   margin-left: 10px;
   vertical-align: 3px;
  }
}



.menu-container {
  @extend .p-0;
  @extend .mb-0;

  li {
    list-style: none;

    .menu-link {
      @extend .d-flex;
      cursor: pointer;
      font-size: $font-big;
      font-weight: $font-w-bold;
      color: $title-color;
      @extend .px-3;
      @extend .py-2;

      .nav-ico {
        svg {
          width: 30px;
          fill: $title-color !important;

          .ico {
            fill: $title-color !important;
          }

          .esagono {
            display: none;
          }
        }

        @extend .me-3;
      }

      &.active, &:hover {
         text-decoration: none;
         background-color: $third-color;
         color: white;
   
         .box,
         .esagono {
           fill: white;
         }
   
         svg {
            path {
               fill: white;
             }

           &.tooltip-ico {
             path {
               fill: white;
             }
           }
         }
       }
    }

    &.menu-item {
      .sub-accordion {
        .card {
          .card-header {
            .btn {
              @extend .px-1;

              .nav-ico {
                svg {
                  width: 37px;
                  @extend .me-0;
                  fill: $primary !important;

                  .ico {
                    fill: $primary !important;
                  }

                  .esagono {
                    display: none;
                  }
                }

                @extend .me-3;
              }

              .arrow-ico {
                @extend .ms-auto;

                svg {
                  width: 10px;
                }
              }

              @extend .d-flex;

              &.panel-toggle {
                font-size: $font-big;
                font-weight: $font-w-bold;
                color: $primary;
                text-transform: none;
              }

              &:hover,
              &:focus {
                text-decoration: none;
              }
            }
          }

          .card-body {
            .menu-container {
              margin-left: -14px;
              margin-right: -14px;

              li {
                .menu-link {
                  font-weight: $font-w-medium;
                }
              }
            }
          }

          &.active-panel {
            border-left: 3px solid $light-primary;

            .card-header {
              .btn {
                .arrow-ico {
                  @extend .ms-auto;

                  svg {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }

          &:last-child {
            border-bottom: 0px;
          }
        }
      }
    }
  }
}

#sidebar-mobile {
  @extend .d-block;
  @extend .d-lg-none;

  .btn-open-menu {
    width: 35px;
    height: 65px;
    position: absolute;
    left: 15px;

    @include media-breakpoint-down(sm) {
      height: 75px;
    }
  }

  .btn-close-menu {
    width: 35px !important;
    height: 65px !important;
    left: 15px;
    top: 0px !important;

    @include media-breakpoint-down(sm) {
      height: 50px !important;
    }
  }

  .left-side-menu {
    background-color: $light-primary;
    padding-top: 75px;

    .applogo {
      max-width: 270px;
      height: auto;
    }

    .home-item {
      position: absolute;
      top: 22px;
      left: 66px;

      @include media-breakpoint-down(sm) {
        top: 14px;
      }
    }
  }

  .accordion {
    .card {
      border: 0px;
      border-radius: 0px;
      box-shadow: none;
      @extend .my-0;
      @extend .px-2;
      border-left: 3px solid $light-primary;
      border-bottom: 1px solid $primary-lighter;

      .card-header {
        border-bottom: 0px;
        background-color: transparent;
        @extend .px-0;

        .btn {
          text-align: left;
          @extend .px-0;

          &.panel-toggle {
            text-decoration: none;
            color: $primary-light;
            text-transform: uppercase;
            font-weight: $font-w-medium;
          }

          &:focus,
          &.focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
          }
        }
      }

      .card-body {
        visibility: hidden;
        @extend .px-0;
        @extend .pt-0;

        .menu-container {
          .menu-link {
            @extend .align-items-center;
          }
        }
      }

      &.active-panel {
        border-left: 3px solid $third-color;

        .card-header {
          .btn {
            &.panel-toggle {
              color: $third-color;
              font-weight: $font-w-bold;
            }
          }
        }

        .card-body {
          visibility: visible;

          .menu-container {
            li {
              &.menu-item {
                .sub-accordion {
                  .card {
                    border-radius: 0px;

                    .card-header {
                      .btn {
                        @extend .px-1;
                        @extend .align-items-center;

                        &.panel-toggle {
                          font-size: $font-big;
                          font-weight: $font-w-bold;
                          color: $primary;
                          text-transform: none;
                        }
                      }
                    }

                    &.active-panel {
                      border-left: 3px solid $light-primary;
                    }

                    &:last-child {
                      border-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.desktop-menu {
  padding-top: 3px;

  .panel-menu {
    width: 130px; //130px
    transition: width 1s;
    background-color: $light-primary;

    .nav-text {
      @extend .d-none;

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    .header-nav {
      padding-right: 1.5rem;
      @extend .py-2;
      @extend .d-flex;
      @extend .justify-content-end;

      .max-minimize {
        width: 10px;
        height: auto;
      }
    }

    &.expanded {
      justify-content: flex-start;
      width: max-content; //250px
      transition: width 1s;
      overflow-x: hidden;

      .nav-text {
        @extend .d-block;
        white-space: nowrap;

        a {
          text-decoration: none;
          color: inherit;
        }
      }

      .header-nav {
        padding-right: 1.2rem;

        .max-minimize {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }
    }

    .menu-container {
      li {
        &.menu-item {
          .menu-link {
            @extend .align-items-center;
          }

          .sub-accordion {
            .card {
              @extend .my-0;
              @extend .p-0;
              box-shadow: 0px 0px 0px;
              border: 0px;

              &.active-panel {
                border-left: 0px;
              }

              .card-header {
                @extend .p-0;
                background-color: transparent;
                border-bottom: 0px;

                .btn {
                  border-radius: 0px;
                  @extend .px-5;
                  @extend .align-items-center;

                  &:focus {
                    box-shadow: 0 0 0 0rem;
                  }
                }
              }

              .card-body {
                .menu-container {
                  @extend .mx-0;

                  .menu-item {
                    .menu-link {
                      font-size: $font-base;

                      .nav-ico {
                        width: 37px;
                        text-align: center;

                        svg {
                          width: 27px;
                          fill: $primary !important;

                          .ico {
                            fill: $primary !important;
                          }

                          .esagono {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dropdown-menu {
   padding: 0;
   border-radius: 12px;

   & > * {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      font-weight: $font-w-medium;
      color: $primary-light;
   }
}

.sub-nav-dropdown, .header-nav-dropdown {
   .dropdown-item.active,
   .dropdown-item:active,
   .dropdown-item:hover,
   .dropdown-item:focus {
     background-color: $third-color !important;
     color: white !important;
     font-weight: $font-w-semibold !important;
   }
 }

.header-nav-dropdown.general {
   .btn {
      svg {
         vertical-align: sub;
      }

      &.active,
      &:active,
      &:hover,
      &:focus {
         svg path {
            fill: white;
            stroke: white;
         }
      }

      &::after {
         display: none;
      }
   }

   .dropdown-item {
      &.active,
      &:active,
      &:hover,
      &:focus {
         svg path {
            fill: white;
            stroke: white;
         }
      }
   }
}

.header-nav-dropdown .dropdown-menu, .header-nav-dropdown.lang .dropdown-menu {
  min-width: max-content;
}

.header-nav-dropdown .dropdown-menu, 
.header-nav-dropdown.lang .dropdown-menu,
.sub-navbar .dropdown-menu {
   overflow: hidden;
   text-align: center;
}

.dropdown-icon {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.7rem;
  fill: $primary-light;
}

.check-text {
  padding-left: 0.7rem;
  padding-top: 0.1rem;
  font-size: initial;
  color: grey;
  margin-bottom: 0;
}

.a-check-text {
  color: $second-color;

  &:hover {
    color: $second-color;
  }
}

.links-nav {
  border-bottom: 1px solid $second-color;
  margin: 0.5rem;
  padding: 2px !important;
}

.black-and-white-svg {
  filter: gray;
  /* IE 6 - 9 */
  filter: grayscale(100%);
  /* Firefox 35 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

#central-nav {
   background-color: $dropdowngrey;
   border-radius: 100px;
   font-weight: $font-w-medium;
   font-size: $font-20px;
   line-height: 21px;
   padding-top: 5px;
   padding-bottom: 5px;
   gap: 5px;

   &:first-child {
      padding-left: 5px;
   }
   &:last-child {
      padding-right: 5px;
   }

   a {
      padding: 15px 25px;
      border-radius: 100px;

      &:hover, &.active {
         background-color: $third-color;
         color: white !important;
      }
   }

}

.dropdown-divider {
   margin: 0;
}

.dropdown-header {
   background-color: $dropdowngrey;
}