span.p-component {
  width: 100%;

  input {
    text-align: right;
    font-family: $font-primary;
  }

  &.sm {
    height: 1.938rem;

    input {
      font-size: 0.875rem !important;
    }
  }

  &.md {
    height: 2.375rem;

    input {
      font-size: 1rem !important;
    }
  }

  &.ml {
   height: 2.3rem;

   input {
     font-size: 1.125rem !important;
   }
 }

  &.lg {
    height: 3rem;

    input {
      font-size: $font-20px !important;
    }
  }

  &.t-center {
    input {
      text-align: center !important;
    }
  }

  &.t-left {
    input {
      text-align: left !important;
    }
  }

  input.p-disabled {
    opacity: 1;
    background-color: #f2f2f2;
    border-color: #e6e6e6;
    color: #999999 !important;
  }
}

.p-datepicker,
.p-datepicker-monthpicker,
.p-ripple-disabled,
.p-connected-overlay-enter-done {
  z-index: 2000 !important;
}
