.not-found.error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: 70%;
    margin: 3rem auto;
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-right: 5rem;
    padding-left: 5rem;

    @media (min-width: 2236px) {
        height: 70%;
    }

    h2 {
        text-transform: inherit;
        font-family: inherit;

        @media (min-width: 2236px) {
            font-size: 2.3rem;
        }
    }

    a {
        color: $second-color;
        &:hover,
        &:visited {
          color: $third-color;
        }

        @media (min-width: 2236px) {
            font-size: 2.1rem;
        }
    }
}

.error-img {
    max-height: 35vh;
    margin-bottom: 2rem;
}