.totalDiv {
   padding-top: 0.35rem;
   padding-bottom: 0.35rem;
   border-top: 1px solid $lightgrey;

   /* .total-label {

   } */

   .total-value {
      font-weight: $font-w-semibold;
   }
}

.bullet-span {
   .bullet {
      display: inline-block;
      width: 1em;
      height: 1em;
      border-radius: 0.5em;
      top: 0.15em;
      position: relative;
   }

   .hexagon {
      width: 1em;
      height: 1em;
   }
}

.span-legend {
   width: 100%;
   line-height: 2.2em;
   display: inline-block;
}

.row-legend {
   justify-content: space-between;
   align-items: center;
   padding: 0;

   @media (max-width: 1535px) {
      font-size: $font-small-1;
   }
}