/* #home {
   .header-site {
      @extend .py-4;

      .text-login {
         .openeconomicslogo {
            @include media-breakpoint-up(md) {
               max-width: 242px;
               margin-left: 30px;
            }

            @include media-breakpoint-down(md) {
               max-width: 50%;
               margin-left: 30px;
            }
         }

         .externalyticslogo {
            //filter: contrast(0.5);

            @include media-breakpoint-up(md) {
               max-width: 160px;
               margin-left: 0.6rem;
            }

            @include media-breakpoint-down(md) {
               max-width: 23%;
               margin-left: 0.6rem;
            }
         }

         small {
            font-size: $font-small;
         }
      }

      .claim {
         @extend .py-3;
         @extend .py-md-0;

         .infoLogin {
            font-size: $font-lg;
            line-height: $font-line-lg;
            margin-bottom: 0.5rem;
         }

         .claimLogin {
            font-size: 27px;
            line-height: $font-lg-3;
            color: $second-color;
            font-weight: $font-w-bold;
         }
      }
   }

   .login-container {
      background-color: #dde4e9;
      background-image: url('../img/backgroundtemp.png');
      background-position: center; //center left
      background-repeat: no-repeat;
      background-size: contain; //auto 100%
      height: 60%;

      @include media-breakpoint-up(md) {
         padding: 60px 0px;
      }

      @media (min-width: '1600px') {
         //padding: 120px 0px;
      }

      .simple-box.form-login {
         margin-top: 0;
         margin-right: 0;
         margin-bottom: 0px;
         @extend .offset-lg-4;
         padding: 40px 49px 37px 48px;
         text-align: center;

         .btn {
            width: 100%;
         }

         .text-muted {
            margin: 1.5rem 0;

            a {
               color: $second-color;
            }
         }

         input {
            text-align: center;
            font-size: $font-big;
            color: $primary-light;
         }

         .user-type {
            font-weight: $font-w-bold;
            font-size: $font-big;
            margin-top: 30px;

            a {
               color: $second-color;
               text-decoration: underline;
            }
         }
      }
   }
} */

.admin-login.form-login {
   padding: 40px 50px;

   .btn {
      width: 100%;
   }

   input {
      text-align: center;
      font-size: $font-big;
      color: $primary-light;
   }

   @media (min-width: '2560px') {
      padding: 40px 120px;
   }
}

.title-login-admin {
   color: $second-color;
   margin-bottom: 2rem;
}

.first-col-login {
   @media (max-width: '991px') {
      margin-top: 1rem;
   }
}