$font-family: "Barlow", sans-serif;
$active-color: rgba(7, 162, 170, 1);
$not-active-color: rgba(102, 102, 102, 1);
$disabled-color: rgba(179, 179, 179, 1);


.nav-tabs {
    .nav-link {
        font-family: $font-family!important;
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
    }
    .nav-link.active {
        font-weight: 600;
        color: $active-color;
    }
    .nav-link:not(.active) {
        color: $not-active-color;
    }
    .nav-link.disabled {
        color: $disabled-color;
    }
}