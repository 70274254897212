.bulletin-title {
   font-weight: 600;
   line-height: 40px;
   margin-bottom: 0.7rem;
   font-size: 2rem;
}

.bulletin-date {
   font-weight: 500;
   color: #B3B3B3;
   line-height: 20px;
   font-size: 20px;
}

.rdw-editor-wrapper {
   font-family: "Barlow", sans-serif;
   font-weight: 400;
   font-size: 18px;
   line-height: 24px;
   color: #1c2743;
   border: 1px solid #b3b3b3;
   border-radius: 8px;
   padding-top: 2px;
   padding: 0;

   .rdw-editor-toolbar {
      border-radius: 8px;
   }

   .rdw-editor-main {
      padding: 0.375rem 0.75rem;
   }

   .public-DraftStyleDefault-block {
      margin: 0.5rem 0 !important;
   }

   a {
      color: $second-color;
   }
}

.Mui-selected {
   background-color: #dff1f2 !important;
}