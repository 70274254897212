#project-wizard {
  .border-tab right {
    text-align: center;
    padding-right: 2rem;

    a.active {
      border: 1px solid $second-color;
      background-color: white;
      padding: 1rem;
      color: $second-color;
      filter: drop-shadow(0px 2px 2px $second-color);
    }

    .first a {
      border: none !important;
      background-color: transparent !important;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  a {
    border: 1px solid $second-color;
    background-color: $second-color;
    color: white;
    padding: 1rem;
    filter: drop-shadow(0px 2px 2px grey);

    .ico-summary {
      width: 38px;
      fill: $second-color;
      stroke: $second-color;
    }

    &.active {
      .ico-summary {
        width: 38px;
        stroke: $second-color;
        fill: white;
      }
    }
  }
}

.link-summary {
  text-align: start;
  text-transform: none;
  //color: $second-color;
  text-decoration: underline;
  font-size: 1.15rem;
}

.show-economics-tab {
  width: 100%;
  margin-top: 2rem;
  overflow: auto;
  text-align: center;

  thead th {
    padding: 10px;
    font-weight: $font-w-semibold;
    //background-color: $first-color;
    //color: #fff;
  }

  thead th:first-child {
    padding-left: 8px;
    text-align: left;
    //width: 50%;
  }

  tbody {
    border-bottom: 0;

    tr {
      padding: 10px 0;
    }

    td {
      padding: 10px;
    }
  }

  tbody td:first-child {
    padding-left: 8px;
    //font-weight: 500;
    text-align: left;
    //width: 50%;
  }

  tbody tr:last-child {
    border-bottom: 0;
  }

  tfoot tr td {
    padding: 1.5rem 8px 10px 10px;
  }

  .totals-row td {
    padding: 1rem 10px;
  }

  svg:hover {
    fill: $second-color;
  }
}

.show-targets-tab {
   margin-top: 2rem;
   overflow: auto;

   thead th {
     font-weight: $font-w-semibold;
   }
 
   tr, td, thead tr, thead th {
      padding: 10px 0 10px 60px;

      @media (max-width: 1366px) {
         padding: 10px 0 10px 30px;
      }

      &:first-child {
          padding-left: 0;
          max-width: 40rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          
          & div, & span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
         }
      }

      &:last-child {
       padding-left: 30px;

       @media (max-width: 1366px) {
         padding-left: 20px;
        }
   }
    }

   svg:hover {
     fill: $second-color;
   }
 }

.cell-width-economic-data {
  max-width: fit-content;
}

.input-candidacy {
  input {
    max-width: 70%;
  }
}

.border-bottom {
  border-bottom: 1px solid #dee2e6;
}

.basis-fit-content {
  flex-basis: fit-content;
}

.underline-height {
  div.col {
    line-height: 2.5rem;
  }
}

#planning-table,
#planning-table-tenders {
  border: 0;

  td,
  tr {
    border: 0;
  }

  tr {
    margin-top: 2rem;
  }
}

#col-space-planning {
   flex: 0 0 auto;
   width: 12.5%;

   @media (min-width: 1536px) {
      width: 17.5%;
   } 

   @media (min-width: 1920px) {
      width: 31.5%;
   } 

   @media (min-width: 2560px) {
      width: 54%;
   } 

   @media (min-width: 3840px) {
      width: 72%;
   } 
}

.span-stream {
  input {
    max-width: 5rem;
  }
}

.link-tender {
  width: fit-content;
  color: $second-color;

  &:hover,
  &:visited {
    color: $second-color-darken;
  }
}

.col-month {
  //col-4
  flex: 0 0 auto;
  width: 33.33333%;

  @media (min-width: '1600px') {
    //col-3
    width: 25%;
  }

  @media (min-width: '1920px') {
    //col-2
    width: 16.66667%;
  }
}

.expanded-accordion {
  .accordion-button:not(.collapsed) {
    background-color: $second-color;
    border-radius: 5px 5px 0 0 !important;
    color: #ffffff;
  }
}

.expanded-sub-accordion {
  .accordion-button:not(.collapsed) {
    background-color: #a4a4a4;
    border-radius: 5px 5px 0 0 !important;
    color: #ffffff;
  }
}

.accordion-item {
  .accordionBorder {
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 10px;
    border-radius: 0.25rem;
  }
}

.target-text {
  font-size: 1.3rem;
  color: #000000bc;
}

.financial-table {
  width: 100%;
  margin-top: 2rem;
  overflow: auto;
  text-align: center;

  thead th {
    padding: 10px;
    font-weight: $font-w-regular;
    //background-color: $first-color;
    //color: #fff;
  }

  thead th:first-child {
    padding-left: 8px;
    text-align: left;
    //width: 50%;
  }

  tbody {
    border-bottom: 0;

    tr {
      padding: 10px 0;
    }

    /* td {
      // padding: 10px;
    } */
  }

  tbody td:first-child {
    padding-left: 8px;
    //font-weight: 500;
    text-align: left;
    //width: 50%;
  }

  tbody tr:last-child {
    border-bottom: 0 !important;
  }

  tfoot tr td {
    padding: 1.5rem 8px 10px 10px;
  }

  .totals-row td {
    padding: 0.6rem 10px;
  }

  svg:hover {
    fill: $second-color;
  }
}

.summaryCheckBox {
  .form-check-input:disabled {
    border-color: $darkgrey;
  }
}

.projectModalRenderBtn {
  font-weight: $font-w-semibold;
  font-size: $font-base;
  line-height: 24px;
  display: contents;
}

.filter-box {
  padding-bottom: 0.3rem;
  border: 2px solid #0000; /* space for the border */
  background:
      //conic-gradient(from 180deg at top 3px right 3px,#0000 90deg, $second-color 0) 90% 0/20px 40px border-box no-repeat,
    conic-gradient(from -90deg at bottom 2px right 2px, #0000 90deg, $second-color 0) 100% 100%/20px
    90% border-box no-repeat;
}

.MuiTreeView-root {
  flex-grow: 1;
  //max-width: 400px;
  overflow-y: auto;
}

.MuiTreeItem-iconContainer {
  svg {
    path {
      fill: $second-color !important;
    }
    g path {
      fill: $second-color !important;
    }
  }
}

.MuiTreeItem-label {
  font-family: $font-primary !important;
  font-size: $font-base !important;
  //line-height: inherit;
}

.MuiTreeItem-content {
  margin-bottom: 0.5rem;
}

.Mui-active {
  color: $second-color !important;
}

.MuiTablePagination-toolbar {
  align-items: baseline !important;
}

.MuiTablePagination-root {
  font-family: $font-primary !important;
  font-size: $font-small-1 !important;
}

.MuiTablePagination-selectLabel {
  font-family: $font-primary !important;
  font-size: $font-small-1 !important;
}

.MuiInputBase-root {
  font-family: $font-primary !important;
  font-size: $font-small-1 !important;
}

.MuiTablePagination-displayedRows {
  font-family: $font-primary !important;
  font-size: $font-small-1 !important;
}

.MuiTablePagination-actions {
  font-family: $font-primary !important;
  font-size: $font-small-1 !important;
}

.MuiButtonBase-root {
  font-family: $font-primary !important;
  font-size: $font-small-1 !important;
}

.align-tree-center-vert {
  display: inline-block;
  vertical-align: middle;
}

.select-statement__control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &:hover {
    border-color: $second-color !important;
    box-shadow: 0 0 0 1px $second-color !important;
  }
}

#errorAccordion {
  .accordionBorder {
    border: none !important;
    // border-color: #cc5c7a;
    .expanded-accordion {
      .accordion-button {
        background-color: #cb1b4a;
        color: #ffffff;

        &::after {
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
        }
      }
    }
    .accordion-collapse {
      background-color: #cb1b4a;
      color: #ffffff;
    }
  }
  // .accordion-item:last-of-type .accordion-collapse {
  //   border-radius: 0 !important;
  // }
}

.transfer-button {
   width: max-content;

   &:disabled {
      border-color: $darkgrey !important;
   }
}

#controlled-tab-framework-tab-null, #controlled-tab-framework-tab-add-target {
   padding-left: 0.7rem;
   padding-right: 0.7rem;
   padding-top: 0.35rem;
   height: 100%;
}

#detail-modal-accordion {
   .accordion-button::after {
      margin-left: inherit !important;
      margin-top: 0.2rem;
      transform: scale(0.9);
   }
}

#detail-modal-close.modal-header { 
   position: absolute;
   right: 1rem;
   z-index: 999;
}

.workpanel-container {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-column-gap: 0px;
   grid-row-gap: 0px;
   height: 100%;
   scrollbar-gutter: stable;

   .title-fixed {
      position: fixed;
      top: 0;
      z-index: 1;
      width: 100%;
   }
}

.workpanel-top {
   grid-area: 1 / 1 / 2 / 3;
   overflow-y: auto;
   overflow-x: hidden;
}

.workpanel-left {
   grid-area: 2 / 1 / 3 / 2;
   overflow-y: auto;
   overflow-x: hidden;
   padding-left: 1.5rem;
}

.workpanel-right {
   grid-area: 2 / 2 / 3 / 3;
   overflow-y: auto;
}

.fake-link-detail {
   padding: 0px;
   color: #09A8B0;
   text-transform: uppercase;
   font-weight: 700;
   font-size: 16px;
   text-decoration: underline;
}

#registry-summary {
   flex-direction: column;

   @media (min-width: 1500px) {
      flex-direction: row; 
      & > .col {
         flex: 0 0 auto;
         width: 50%; //.col-6
         padding: 2rem;
      }
   }

   @media (min-width: 1920px) {
      & > .col {
         flex: 0 0 auto;
         width: 25%; //.col-3
      }
   }
}

.badge {
   &.bg-secondary {
      background-color: $second-color !important;
   }
}