@import "~bootstrap/scss/bootstrap";
@import "~billboard.js/src/scss/billboard.scss";

@import "variables";
@import "header";
@import "footer";
@import "layout";
@import "home";
@import "App";

@import "general";
@import "dashboard";
@import "login";
@import "tooltips";
@import "cardTable";
@import "projects";
@import "project";
@import "404";
@import "usersManagement";
@import "setup";
@import "checklist";
@import "chartCard";
@import "modals";
@import "tabs";
@import "forms";
@import "bulletins";

@import "primereact";
@import "customMaterial";