div.tooltipInfoBox {
   background-color: #3b3b3b;
   color: #f9f9f9;
   position: absolute;
   top: 104%;
   left: 0;
   max-width: 20vw;
   width: max-content;
   transform: translate(-30%, 0);
   margin-bottom: 15px;
   // text-align: center;
   font-size: 16px;
   z-index: 999;
   padding: 0.8em !important;
   border-radius: 0.5em;
   line-height: normal;
   font-weight: initial;
   font-family: $font-primary;
   text-align: initial;
   text-transform: initial;
}

//prime-react tooltip
.p-tooltip {
   width: 20vw;
   border-radius: 6px;
   margin-top: 3px;
}

/* .p-tooltip {
   top: 104%;
   margin-bottom: 15px;
} */

.p-tooltip-arrow {
   border-bottom-color: $primary !important;
}

.p-tooltip-text {
   background-color: $primary !important;
   color: #f9f9f9;
   font-family: $font-primary !important;
   font-size: 14px;
   font-variant-numeric: tabular-nums; 
   font-weight: 400;
   text-align: center !important;
   line-height: 22px !important;
   padding: 5px 8px !important;
   max-width: 200px;
   text-wrap: wrap;
   white-space-collapse: collapse;
}