$primary-color: rgba(7, 162, 170, 1);
$secondary-color: rgba(28, 39, 67, 1);
$font-family: "Barlow", sans-serif;

.modal-content {
    .modal-header {
        border-bottom: 0;
        .modal-title {
            font-size: 30px!important;
            font-weight: 600!important;
            line-height: 30px!important;
            color: $secondary-color!important;
            font-family: $font-family;
        }

        .btn-close {
            z-index: 2;
        }
    }

    .overflow-hidden.modal-body {
      padding-left: 0;
      padding-right: 0;
   }

    .modal-footer {
        border-top: 0;
        .btn {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            padding:  8px, 16px, 8px, 14px;
            border-radius: 8px;
            font-family: $font-family;
            min-width: 140px;
          
            &.btn-primary {
                background-color: $primary-color;
                border-color: $primary-color;
                color: #fff;
                &:hover {
                    background-color: $primary-color;
                    border-color: $primary-color;
                    color: #fff;
                }
            }
            &.btn-secondary {
                background-color: #fff;
                border-color: $primary-color;
                color: $primary-color;
                &:hover {
                    background-color: #fff;
                    border-color: $primary-color;
                    color: $primary-color;
                }
            }
            &.btn-outline-danger {
               &:hover {
                  svg {
                     fill: #fff;
                     path {
                        fill: #fff;
                     }
                  }
               }
           }
        }
    }
}