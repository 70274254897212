#progetti, #setup, #tenders, #reports, #project, #profile {
  .number-row {
    @include media-breakpoint-down(sm) {
      .card {
        font-size: $font-base;
      }
    }

    @include media-breakpoint-up(md) {
      .card {
        font-size: $font-big;
      }
    }
  }
}

div.projectstab {
  .scenario-link {
    color: $second-color;
  }
}

span.project-details {
  font-size: 1.1rem;
  text-transform: none;
  color: #666;
  font-weight: normal;
  padding: 0 1rem;
  border-right: 1px solid #666;

  &.no-pipe {
    border-right: none;
  }
}

.bluicon {
  color: #09A8B0;
}

.w150 {
  min-width: 150px;
}

.ag-theme-alpine {
  font-size: 1.1rem !important;
  font-family: inherit !important;
  /* --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%; 
   --ag-alpine-active-color: #dff1f2 !important;*/

   --ag-selected-row-background-color: #dff1f2;
   --ag-row-hover-color: #dff1f2;
   --ag-borders: true;
   --ag-header-column-separator: true;
   --ag-header-column-separator-height: 100%;
   --ag-header-column-separator-width: 3px;

   .ag-header-row {
      background-color: #F8F9FA !important;
   }

   &.projects-list {
      .ag-header-row {
         background-color: #F8F9FA !important;
      }
   }

  .ag-header {
    background-color: inherit !important;
    border-bottom-color: #E6E6E6 !important;
  }

  .ag-header-cell {
    color: #1c2743;
    font-weight: bold;
  }

  .ag-header-cell-label {
    padding: 0.6rem 0;
  }

  .ag-cell {
    padding: 0.6rem 0.1rem;
    border-left: 1px solid #E6E6E6 !important;
    border-collapse: collapse !important;
  }
  
  .ag-header-cell-resize:after {
      background-color: #E6E6E6 !important;
  }

  .ag-row {
    font-size: 1.1rem !important;
    border-bottom-color: #E6E6E6 !important;
  }

  .ag-root-wrapper {
    border: 0 !important;
    overflow: visible; //show tooltip
  }

  @include media-breakpoint-down(sm) {
    .ag-paging-panel {
      font-size: 0.8rem !important;
      margin-bottom: 1rem;
    }
  }

  .ag-header-cell-filtered {
   background-color: $second-color !important;
   color: #fff !important;
 }
 
 .ag-header-cell-filtered span {
   color: #fff !important;
 } 

 /* .ag-row-hover, .ag-row-selected {
   background-color: #dff1f2 !important;
 } */

 /* .ag-popup {
   z-index: 10000 !important;
   overflow: visible !important;
 } */

   .ag-center-cols-clipper .ag-row-level-0 > div.ag-cell:first-child {
      border-left: 0 !important;
   }

   .ag-floating-bottom .ag-cell {
      border-left: 0 !important;
   }
}

#grid-wrapper {
  width: 100%;
  //height: 100%;
}

.list-table {
  width: 100%;
  //height: 100%;

  /* .ag-body-horizontal-scroll {
    display: none;
  } */

  .ag-horizontal-right-spacer::-webkit-scrollbar {
    display: none;
  }
}

.page-size {
  border: none;
  text-align: center;

  &:focus-visible {
    outline: none;
  }
}

.font-lg-2 {
  font-size: $font-lg-2;
}

.min-label-width {
  min-width: max-content;
}

.lineheight-initial {
  line-height: initial;
}

.sroi-details-padding {
  padding: 0;

  @media (min-width: 1367px) {
    padding-left: 1.5rem;
  }
}

.new-button {
  margin-right: 5px !important;
  padding-bottom: 2px;
}

.border-bottom-grey {
   border-bottom: 1px solid $lightgrey;
}

.border-bottom-first {
  border-bottom: 1px solid $first-color-disabled;
}

.border-bottom-second {
  border-bottom: 1px solid $second-color-disabled;
}

.border-bottom-third {
  border-bottom: 1px solid $third-color-disabled;
}

.summary-section {
  margin-left: 0;
  margin-right: 0;

  .summary-row {
    flex-direction: column;
    margin-right: 1rem;
  }

  .summary-row .summary-col {
    text-align: center;
    padding-left: inherit;
    padding-right: inherit;
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: '1920px') {
    .summary-row {
      flex-direction: row;

      .summary-col {
        text-align: left;
        margin-left: 0;
        margin-right: 0;
      }
    }

    .summary-row .summary-col:first-child {
      padding-left: 1rem;
    }

    .summary-row .summary-col:nth-child(2) {
      text-align: right;
      margin-right: 2rem;
      padding-left: 0;
      padding-right: 1rem;
    }
  }
}

.dataHeaderName {
  font-size: 0.9rem;
  text-transform: uppercase;
  opacity: 0.8;
}

.one-line-text {
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
}

#accordion-summary {
   .accordion-button {
      background-color: #F3FAFB !important;

      &:not(.collapsed) {
         color: inherit !important;
      }
   }
}

.as-aggrid-checkbox input {
   /* width: 16px !important;
   height: 16px !important; */
   border-radius: 2px !important;
   border-color: #999 !important;
   border-width: 2px !important;
   margin-left: -2px !important;
   cursor: pointer;
}