#site-footer {
   padding-bottom: 0 !important;
   bottom: 0;
   //position: fixed;
   width: 100%;
   background-color: $light-primary;
   font-size: $font-small;
   color: $primary-light;

   a {
      color: $primary-light;
      text-decoration: none;
   }

   .openeconomicslogo {
      max-width: 315px;
      height: auto;


      @include media-breakpoint-down(sm) {
         max-width: 242px;
      }
   }

   .footer-menu {
      @media (min-width: 992px) {
         padding: 1rem 0;
      }

      .footer-menu-item {
         display: inline-block;
         border-right: 1px solid $primary-light;
         @extend .px-3;

         &:last-child {
            border-right: 0px;
         }

         @include media-breakpoint-down(sm) {
            border-right: 0px;
            margin-bottom: 10px;
            display: block;
         }
      }

      .symbol {
         fill: $primary;
      }
   }

   .copyright {
      @media (max-width: '991px') {
         margin-bottom: 1rem;
      }
   }
}