#login {
  background: $primary-lightest;

  .btn {
    background-color: $first-color;
    border-color: $first-color;
    color: #ffffff;
  }

  .btn-nav {
    background-color: $third-color;
    border-color: $third-color;
    color: #ffffff;
  }

  .btn-nav .active {
    background-color: $first-color;
    border-color: $first-color;
    color: #ffffff;
  }

  .nav {
    color: $primary-lighter;
    .active {
      color: $first-color;
    }
  }
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    table-layout: fixed;
    width: 100%;
  }
  table tbody {
    display: table;
    width: 100%;
  }

  a {
    color: $primary-light;
    &:hover,
    &:visited {
      color: $primary-light;
    }
  }
}

.eye {
  width: 1.4rem;;
  height: auto;
  fill: $title-color;
}

.errorMessage {
  color: $red;
  
  h5 {
    margin: 1.5rem auto !important;
  }
}

#link-access {
  color: $second-color;
}