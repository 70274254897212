.accordion .accordion-title {
  cursor: pointer;
  border-bottom: 1px dotted $third-color;

  /* .tooltip-inner {
     max-width: 300px !important;
   } */
}

.accordion-arrow {
  width: 25px;
  height: 25px;
}

.accordion-button {
  font-size: $font-20px;

  &:not(.collapsed) {
    background-color: transparent;
    border-radius: 5px 5px 0 0 !important;
    color: $second-color;
  }
}

.hr-question {
  border-top: 1px dotted;
}

.question-code {
  width: fit-content;
  -moz-border-radius: 5%;
  border-radius: 5%;
  border: solid 2px $third-color;
  color: $first-color;
  text-align: center;
  display: block;
  font-size: small;
  font-weight: 400;
  padding: 0 0.3rem;

  &.instruction {
    border-color: $second-color;
  }
}

.summary-check {
  padding: 0;
  color: $red;
  font-weight: 500;
  max-width: 3rem;
}

.checklist-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  margin-bottom: 1rem;
  height: 2.5rem;
  width: 100%;
}

.checklist-scroll {
  overflow-y: auto;
  //max-height: calc(100vh - 300px);
  scrollbar-gutter: stable;
  max-height: 83vh;
}

.cl-custom-width {
  //max-width: 3.153rem;
  max-width: inherit;
  padding-right: 3rem;
}

.dnshRadioAnswer {
  margin-right: 0;
}