.border-tab {
   &.top {
      border-top: 2px solid $third-color;
   }

   &.right {
      border-right: 2px solid $third-color;
   }
  
   &.bottom {
      border-bottom: 2px solid $third-color;
   }

   &.left {
      border-left: 2px solid $third-color;
   }
}

.user-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  width: 11rem;
  font-size: 1.2rem;
}

.create-edit-user-button {
  border-color: $second-color;
}

.delete-user-button {
  border-color: $red;
}

#administration.megaContent,
#setup.megaContent,
#tenders.megaContent,
#profile.megaContent,
#clients.megaContent {
  @media (min-width: 4096px) { 
    padding-top: 8rem;
  }
}

#project.megaContent,
#setup.megaContent,
#clients.megaContent {
  padding-top: 1px;
}