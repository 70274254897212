$font-family: "Barlow", sans-serif;
$primary-color: rgba(28, 39, 67, 1);
$required-color: rgba(202, 27, 74, 1);
$focused-color: rgba(7, 162, 170, 1);
$focused-color-option: rgba(8, 162, 170, 0.15);

form {
    .p-component {
        font-family: $font-family;
        .p-inputtext {
            font-family: $font-family;
            font-size: 18px;
            color: $primary-color;
            background: #ffffff;
            border: 1px solid #b3b3b3;
            transition: none;
            appearance: none;
            border-radius: 8px;
            padding: 6px 12px;
        }
        .p-inputtext:disabled, .p-inputtext[readonly], .p-inputtext.p-disabled {
            background: rgba(230, 230, 230, 1);
            color: #b3b3b3;
            border-color: #b3b3b3;
        }
        .p-inputtext:enabled:hover {
            border-color: $focused-color;
        }
        .p-inputtext:enabled:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
            border-color: $focused-color;
        }
        .p-inputtext.p-invalid.p-component {
            border-color: $required-color;
        }
    }
    .form-label {
        font-family: $font-family;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $primary-color;
        margin-bottom: 15px;
    }
    .form-control {
        font-family: $font-family;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $primary-color;
        border: 1px solid rgba(179, 179, 179, 1);
        border-radius: 8px;
        &::placeholder {
            color: rgba(179, 179, 179, 1);
        }
    }
    .form-control:focus {
        border-color: $focused-color;
    }
    .form-control:disabled,
    .form-control[readonly] {
        background: rgba(230, 230, 230, 1);
        color: rgba(179, 179, 179, 1);
        border-color: #b3b3b3;
    }
    .react-select-container {
        .react-select__control {
            font-family: $font-family;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: $primary-color;
            border: 1px solid rgba(179, 179, 179, 1);
            box-shadow: none;
            border-radius: 8px;
            &.react-select__control--is-focused {
                border-color: $focused-color;
            }
            .react-select__value-container {
                color: $primary-color;
            }
            .react-select__indicators {
                color: $primary-color;
            }
        }
        .react-select__control--is-disabled {
            background: rgba(230, 230, 230, 1);
            color: rgba(179, 179, 179, 1);
        }
        .react-select__menu {
            z-index: 100;
            .react-select__menu-list {
               z-index: 100;
                .react-select__option {
                  z-index: 100;
                    font-family: $font-family;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    color: $primary-color;
                }
                .react-select__option--is-focused,
                .react-select__option--is-selected {
                    background: $focused-color-option;
                }
            }
        }

      &.value-nowrap {
         .react-select__single-value {
            white-space: normal;
         }
      }
    }
    .form-select {
        font-family: $font-family;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $primary-color;
        border: 1px solid rgba(179, 179, 179, 1);
        border-radius: 8px;
        &::placeholder {
            color: rgba(179, 179, 179, 1);
        }
    }
    .form-select:focus {
        border-color: $focused-color;
        box-shadow: none;
    }
    .form-text {
        font-family: $font-family;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $primary-color;
    }
}

.label {
    font-family: $font-family;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $primary-color;
    margin-bottom: 15px;
}

.required-field {
    color: $required-color;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    font-family: $font-family;
}
