#dashboard {
  background: $primary-lightest;

  .impact {
    .chartBox {
      padding: 1em 3em;
      h5 {
        text-align: center;
        margin-top: 0.5em;
      }
      &.impactChart {
        min-width: 210px;
        width: 16.65%;
        h5 {
          text-transform: uppercase;
          &.impact-total {
            font-size: $font-line-lg;
            line-height: $font-line-lg;
            font-weight: $font-w-semibold;
            margin-top: 1em;
          }
        }
        a {
          color: #000;
          &:hover,
          &:visited {
            color: #000;
          }
        }
        .va-desc {
          @extend .text-center;
          font-size: $font-small;
        }
      }
    }
  }

  .container-card {
    //@extend .d-flex;
    .card,
    .simple-box {
      flex: 1;
      div.tooltipInfoBox {
        @include media-breakpoint-up(sm) {
          width: 12vw;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  //GLF Fix for adapting SVG images
  .img-card {
    width: 100%;
  }
}

.report-2 {
  margin: 20px 0;
  gap: 2rem;

  @media (min-width: 1920px) {
    gap: 3rem;
  }
}

.box {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 1.1rem;
}

.legend-text {
  text-align: left;

  span {
    color: $third-color;
    margin: 0 0.3rem;
  }
}

.green {
  background-color: $green !important;
}

.yellow {
  background-color: $yellow !important;
}

.red {
  background-color: $red !important;
}

.grey {
  background-color: rgb(205, 205, 205);
}

#media-portafoglio {
  //flex-basis: auto;
  margin-top: 0;

  @media (min-width: 1600px) {
    //flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    margin-top: 3.5rem;

    @media (max-width: 2560px) {
      .media-text {
        inline-size: auto;
        margin: auto;
      }
    }
  }

  @media (max-width: 1600px) {
    flex-basis: auto;
    flex-grow: 1;
    max-width: 100%;
  }
}

#radarBenefits,
#radarSroi {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: inherit;
  gap: 2rem;

  div {
    gap: 4rem;
  }

  @media (min-width: 4096px) {
    flex-direction: row;
    gap: 0rem;

    div {
      margin-top: 0.4rem;
      align-self: flex-start;
    }
  }
}

#donut-legend {
  margin-top: -2.2rem;
  padding-left: 2rem;

  div div:first-child {
    margin-bottom: 1rem !important;
  }

  div div {
    margin-bottom: 0.55rem !important;
  }
}

#radarBenefits {
  div {
    flex-wrap: nowrap;
  }

  @media (min-width: 4096px) {
    justify-content: space-between !important;
    margin-left: 16rem;

    div {
      gap: 4rem;
    }
  }
}

#radarSroi {
  flex-grow: 1.4;

  @media (min-width: 4096px) {
    justify-content: space-around !important;
    margin-left: 10rem;

    div {
      gap: 5rem;
    }
  }
}

.dashChartBox {
  padding: 1em;
  h5 {
    text-align: center;
    margin-top: 0.5em;
  }
  &.donutCharts {
    min-width: 210px;
    width: 16.65%;
    margin-left: 1rem;
    margin-right: 1rem;

    @media (max-width: 1366px) {
      width: 13%;
      //min-width: 160px;
      //max-width: 190px;
      margin-left: 0;
      margin-right: 0;
    }

    @media (max-width: 1660px) {
      width: 13%;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    @media (max-width: 1920px) {
      width: 13%;
      margin-left: 2rem;
      margin-right: 2rem;
    }

    @media (min-width: 2560px) {
      width: 13%;
      margin-left: 2rem;
      margin-right: 2rem;
    }

    @media (min-width: 3840px) {
      width: 10%;
      margin-left: 5rem;
      margin-right: 5rem;
    }

    h5 {
      text-transform: uppercase;
      &.impact-total {
        font-size: $font-line-lg;
        line-height: $font-line-lg;
        font-weight: $font-w-semibold;
        margin-top: 1em;
      }
    }
    /*  a {
       color: #000;
       &:hover,
       &:visited {
         color: #000;
       }
     } */
    .va-desc {
      @extend .text-center;
      font-size: $font-small;
    }
  }
}

.svg-tooltip {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple   Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background: rgba(69, 77, 93, 0.9);
  border-radius: 0.1rem;
  color: #fff;
  display: block;
  font-size: 14px;
  max-width: 320px;
  padding: 0.2rem 0.4rem;
  position: absolute;
  text-overflow: ellipsis;
  //white-space: pre;
  z-index: 300;
  visibility: hidden;
}

.color-legend {
  width: 8px;
  border-radius: 5px;
  margin: 0.3rem 0;
  padding: 0;
}
